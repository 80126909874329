import { Injectable } from '@angular/core';
import 'rxjs/add/operator/do';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class SessionCheckInterceptor implements HttpInterceptor {
  sessionTimeCount = 60 * 1000;
  timer;
  constructor(private router: Router) {
    this.sessionTimeCount = (environment.sessionTimeOut - 1) * this.sessionTimeCount;
    this.resetTimer();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.resetTimer()
      }
    });
  }

  logout() {
    var self = this;
    let timerInterval
    Swal.fire({
      title: 'You are about to be signed out',
      html: 'Due to inactivity, you will be signed out in <b></b> seconds.',
      timer: 1*60*1000,
      timerProgressBar: true,
      showCancelButton:true,
      cancelButtonText:'Stay Signed In',
      cancelButtonColor:'#3085d6',
      didOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              (b as any).textContent = Math.floor(Swal.getTimerLeft()/1000 % 60)
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval);
        // this.router.navigateByUrl('/login');
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        this.router.navigateByUrl('/authentication/logout',{state:{local:true,returnUrl:'inactive'}});
      } else {
        this.resetTimer();
      }
    })
  }
  resetTimer() {
    if (this.timer)
      clearTimeout(this.timer);
    this.timer = setTimeout(() => {this.logout()}, this.sessionTimeCount);
  }
}
