<div class="main-wrapper">
  <div class="preloader" style="display: none;">
      <div class="lds-ripple">
          <div class="lds-pos"></div>
          <div class="lds-pos"></div>
      </div>
  </div>
  <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(https://sageitinc.com/sagefiles/2022/04/sageit-cloudservices.jpg) no-repeat center center;">
      <div class="auth-box" style="opacity:0.9"  *ngIf="message && (message | async)?.length > 0 && (message | async) !== 'Please wait...'">
          <div id="loginform">
              <div class="logo">
                  <span class="db"><img src="/images/StrateFlow_black.png" alt="logo" style="height:45px;"></span>
              </div>
              <div class="row">
                  <div class="col-12 text-center mt-3">
                    <h5 class="font-medium m-b-20">{{ message | async }}</h5>
                    <p *ngIf="message && (message | async)?.length > 0 && (message | async) !== 'Please wait...'">
                      <a [routerLink]="['/']" >Return to Login</a>
                    </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
