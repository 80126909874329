<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" 
        href="javascript:void(0)" data-sidebartype="mini-sidebar">
            <i class="sl-icon-menu font-20"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Comment -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item" ngbDropdown>
        <a ngbDropdownToggle class="nav-link waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ti-bell font-20"></i>

        </a>
        <div class="mailbox" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">4 New</h4>
                        <span class="font-light">Notifications</span>
                    </div>
                </li>
                <li>
                    <a class="nav-link text-center m-b-5" href="javascript:void(0);">
                        <strong>Check all notifications</strong>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </li> -->
     <!-- ============================================================== -->
    <!-- create new -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-left" *ngIf="!isReadOnly">
      <!-- <a ngbDropdownToggle class="nav-link" href="javascript:void(0)" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <i class="ti-plus font-20"></i>
      </a> -->
      <!-- <div class="dropdown-menu-left" aria-labelledby="navbarDropdown2" ngbDropdownMenu> -->
        <!-- <span class="with-arrow">
          <span class="bg-primary"></span>
      </span> -->
          <!-- <a class="dropdown-item" [routerLink]="['/tickets/create']">
              <i class="ti-ticket"></i> Onboarding Request</a> -->
          <!-- <a class="dropdown-item" [routerLink]="['/projects/create']">
            <i class="ti-ticket"></i>New Project</a> -->
          <!-- <a class="dropdown-item" [routerLink]="['/assets/create']" *ngIf="isAdmin || isAgent">
              <i class="ti-harddrives"></i> Asset</a>
          <a class="dropdown-item"[routerLink]="['/users/create']" *ngIf="isAdmin">
              <i class="ti-user"></i> User</a> -->
      <!-- </div> -->
  </li>
    <!-- ============================================================== -->
    <!-- End Comment -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close font-16"></i>
            </a>
        </form>
    </li> -->

    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img src="assets/images/user-logo.svg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <img src="assets/images/user-logo.svg" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{userdata?.Firstname}} {{userdata?.Lastname}}</h4>
                    <p class=" m-b-0">{{userdata?.email}}</p>
                </div>
            </div>
            <!--<a class="dropdown-item" href="/Identity/Account/Manage">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>-->
            <a class="dropdown-item" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' title="Logout">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>
