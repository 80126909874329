import { RouteInfo } from './sidebar.metadata';
import { UserRoles } from 'src/app/utils/string-constants';

export const ROUTES: RouteInfo[] = [
  //{
  //  path: '/dashboard',
  //  title: 'Dashboard',
  //  icon: 'fas fa-tachometer-alt',
  //  class: '',
  //  extralink: false,
  //  submenu: [
  //    // {
  //    //   path: '/dashboard/functionalhead',
  //    //   title: 'Overall Dashboard',
  //    //   icon: '',
  //    //   class: '',
  //    //   extralink: false,
  //    //   submenu: [],
  //    //   roles:[UserRoles.Administrator,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,UserRoles.Developer, UserRoles.DeliveryManager]
  //    // },
  //    // {
  //    //   path: '/dashboard/organizationalimpact',
  //    //   title: 'Organizational Impact Dashboard',
  //    //   icon: '',
  //    //   class: '',
  //    //   extralink: false,
  //    //   submenu: [],
  //    //   roles:[UserRoles.Administrator,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,UserRoles.Developer, UserRoles.DeliveryManager]
  //    // },
  //    // {
  //    //   path: '/dashboard/projectstatus',
  //    //   title: 'Project Status Dashboard',
  //    //   icon: '',
  //    //   class: '',
  //    //   extralink: false,
  //    //   submenu: [],
  //    //   roles:[UserRoles.Administrator,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,UserRoles.Developer, UserRoles.DeliveryManager]
  //    // },
  //    // {
  //    //   path: '/dashboard/budget',
  //    //   title: 'Budget Dashboard',
  //    //   icon: '',
  //    //   class: '',
  //    //   extralink: false,
  //    //   submenu: [],
  //    //   roles:[UserRoles.Administrator,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,UserRoles.Developer, UserRoles.DeliveryManager]
  //    // },
  //  ],
  //  roles:[UserRoles.Administrator,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,UserRoles.Developer, UserRoles.DeliveryManager]
  //},
  // {
  //   path: '/projects',
  //   title: 'Projects',
  //   icon: 'ti-ticket',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator,UserRoles.Employee,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,,UserRoles.Developer, UserRoles.DeliveryManager]
  // },
  

  // {
  //   path: '/assets',
  //   title: 'Assets',
  //   icon: 'ti-harddrives',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator,UserRoles.Employee,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,,UserRoles.Developer, UserRoles.DeliveryManager]
  // },
  // ,
  // {
  //   path: '/catalog',
  //   title: 'Product Catalog',
  //   icon: 'fas fa-cubes',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator,UserRoles.Employee,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,,UserRoles.Developer, UserRoles.DeliveryManager]
  // },
  // {
  //   path: '/reports',
  //   title: 'Reports',
  //   icon: 'ti-bar-chart',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // },
  // {
  //   path: '',
  //   title: 'Configuration',
  //   icon: 'sl-icon-settings',
  //   class: '',
  //   extralink: true,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // },
  {
    path: '/home',
    title: 'Home',
    icon: 'ti-home',
    class: '',
    extralink: false,
    submenu: [],
    roles:[UserRoles.Administrator,UserRoles.Employee,UserRoles.Agent,UserRoles.SystemManager,UserRoles.BA,,UserRoles.QA,,UserRoles.Developer, UserRoles.DeliveryManager]
  },
  {
    path: '/users',
    title: 'Users',
    icon: 'ti-user',
    class: '',
    extralink: false,
    submenu: [],
    roles:[UserRoles.Administrator]
  },
  // {
  //   path: '/admin/usergroups/list',
  //   title: 'User Groups',
  //   icon: 'sl-icon-people',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // },
  //{
  //  path: '/admin/emails',
  //  title: 'Email Templates',
  //  icon: 'ti-email',
  //  class: '',
  //  extralink: false,
  //  submenu: [],
  //  roles:[UserRoles.Administrator]
  //},
  // {
  //   path: '/admin/customfields',
  //   title: 'Custom Fields',
  //   icon: 'mdi mdi-dns',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // },
  // {
  //   path: '/admin/ticketcategories',
  //   title: 'Project Categories',
  //   icon: 'mdi mdi-notification-clear-all',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // },
  // {
  //   path: '/admin/rules',
  //   title: 'Assignment Rules',
  //   icon: 'fas fa-sitemap fa-rotate-90',
  //   class: '',
  //   extralink: false,
  //   submenu: [],
  //   roles:[UserRoles.Administrator]
  // }
];
