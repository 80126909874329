import {
  Component,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { ApplicationPaths } from 'api-authorization/api-authorization.constants';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthorizeService, IUser } from 'api-authorization/authorize.service';
import { BehaviorSubject } from 'rxjs';
import { StringConstants, UserRoles } from 'src/app/utils/string-constants';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public userdata;
  isReadOnly = true;
  isAdmin = false;
  isAgent = false;

  constructor(private modalService: NgbModal, private auth: AuthorizeService) {}

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM',
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM',
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM',
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM',
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM',
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  ngOnInit() {
    this.auth.getUser().subscribe((data) => {
      this.userdata = data;
      if (data == null) {
        window.location.href = window.location.href;
      } else {
        this.isReadOnly = (data as any)?.role?.includes(UserRoles.Employee) ?? true;
        this.isAdmin = (data as any)?.role?.includes(UserRoles.Administrator) ?? false;
        this.isAgent = (data as any)?.role?.includes(UserRoles.Agent) ?? false;
      }
    });
  }

  handleLogout() {
  }
}
