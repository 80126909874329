import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldComponent } from './custom-field/custom-field.component';
import { CustomFormComponent } from './custom-form/custom-form.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [CustomFieldComponent, CustomFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [CustomFieldComponent, CustomFormComponent]
})
export class CustomUIModule { }
