export class StringConstants {
  public static readonly ResolutionValues = ['Cancelled','Fixed','Fixed with Workaround','Not a Defect','Duplicate'];
}

export class ErrorMessages {
  public static readonly genericError =
    'Something went wrong, please try again later';
}

export enum FilterType {
  AssignedTome,
  OpenTickets,
  ClosedTickets,
}


export class UserRoles {
  public static readonly Administrator = 'Administrator';
  public static readonly Agent = 'Agent';
  public static readonly Employee = 'User';
  public static readonly SystemManager = 'System Manager';
  public static readonly Developer = 'Developer';
  public static readonly QA = 'QA';
  public static readonly BA = 'Business Analyst';
  public static readonly DeliveryManager = 'Delivery Manager';
}

import Quill from 'quill';
import ImageResize from 'quill-image-resize-module'

Quill.register('modules/imageResize', ImageResize);

export class ConfigurationValues {
  public static readonly quillOptions = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ 'font': [] }],
        [{ align: [] }],

        // ['clean'],                                         // remove formatting button

        ['link', 'image']                         // link and image, video
      ],
      imageResize: true
    },
  };
}
