import { Component, AfterViewInit, OnInit } from "@angular/core";
import { ROUTES } from "./menu-items";
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthorizeService } from "api-authorization/authorize.service";
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu = "";
  showSubMenu = "";
  public sidebarnavItems: any[];
  userdata;
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = "0";
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = "0";
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthorizeService
  ) {}

  // End open close
  ngOnInit() {
    this.auth.getUser().subscribe((data) => {
      this.userdata = data;
      this.sidebarnavItems = ROUTES.filter((sidebarnavItem) => sidebarnavItem);
      this.sidebarnavItems = this.sidebarnavItems.filter((el) =>
        el.roles.some((r) => this.userdata.role.includes(r))
      );
    });
  }
}
